import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { buildImageObj } from "../utils/helpers";
import { imageUrlFor } from "../utils/image-url";
import moni from "../images/moni.svg";
import advantage from "../images/advantage_club.svg";
import topship from "../images/topship.png";
import nairametrics from "../images/nairametrics.png";

const TestimonialStyles = styled.div`
    .testimonial {
        display: grid;
        grid-template-columns: 347px 804px;
        /* grid-column-gap: 73px; */
        justify-content: space-between;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
        .testimonial {
            display: flex;
            flex-direction: column;
        }
    }
    @media (min-width: 481px) and (max-width: 768px) {
        .testimonial {
            display: flex;
            flex-direction: column;
        }
    }
    @media (min-width: 320px) and (max-width: 480px) {
        .testimonial {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
        }
    }
    @media (min-width: 769px) and (max-width: 1024px) {
        .share-idea {
            margin: 0 auto;
        }
    }
    @media (min-width: 320px) and (max-width: 480px) {
        .share-idea {
        }
    }
    .buttonwrapper {
        border-radius: 100px;
        margin-top: 32px;
    }
    @media (min-width: 320px) and (max-width: 480px) {
        .buttonwrapper {
            justify-content: center;
        }
    }
    button,
    a.secondary-link,
    a {
        width: 300px;
        display: flex;
        align-items: center;
        border-color: #ffffff;
        border-image: none;
        background-color: #ffffff;
        color: #5974aa;
        font-size: 18px;
        font-family: "Graphik";
        font-weight: 600;
        border-width: 0px;
        border-style: unset;
    }
    button,
    a.secondary-link,
    a:hover svg {
        transition: all 0.4s ease 0s;
        transform: translateX(7px);
    }
    @media (min-width: 320px) and (max-width: 480px) {
        button {
            font-size: 14px;
        }
    }

    @media (min-width: 320px) and (max-width: 480px) {
        a {
            font-size: 14px;
        }
    }
    .group214 {
        display: grid;
        grid-template-columns: repeat(4, 150px);
        grid-template-rows: 150px;
        grid-column-gap: 68px;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
        .group214 {
            margin: 0 auto;
            padding-top: 50px;
        }
    }
    @media (min-width: 481px) and (max-width: 768px) {
        .group214 {
            width: 100%;
            grid-template-columns: repeat(4, 100px);
            grid-template-rows: 100px;
            justify-content: space-between;
            padding-top: 50px;
        }
    }
    @media (min-width: 320px) and (max-width: 480px) {
        .group214 {
            display: grid;
            grid-template-rows: repeat(2, 150px);
            grid-template-columns: repeat(2, 150px);
            grid-gap: 20px;
            margin: 30px auto 0 auto;

            /* margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2,150px);
    grid-template-rows: repeat(2,150px);
    grid-gap: 20px; */
        }
    }
    .rectangle {
        width: 150px;
        height: 150px;
        position: relative;
    }
    .rectangle img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .share-paraph {
        font-size: 36px;
        letter-spacing: -4%;
        font-weight: 600;
        font-family: "Graphik";
        text-transform: none;
        padding-left: 0;
        line-height: 50px;
        color: #03060b;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
        .share-paraph {
            /* font-size: 32px; */
        }
    }
    @media (min-width: 320px) and (max-width: 480px) {
        .share-paraph {
            font-size: 24px;
            line-height: 36px;
            text-align: center;
        }
    }
    @media (min-width: 320px) and (max-width: 480px) {
        .secondary {
            width: 100%;
            /* margin: 0 auto; */
            /* margin-right: 20px; */
            margin-left: 20px;
        }
    }
`;

function Testimonial() {
    return (
        <TestimonialStyles>
            <div className="testimonial">
                <div className="share-idea">
                    <p className="share-paraph">We support founders building a better future for everyone</p>
                    <div className="buttonwrapper">
                        <button className="secondary">
                            <Link to="/venture">
                                Learn more about our ventures
                                <span>
                                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M3.29289 5.50001L0 2.20712L1.41421 0.792908L6.12132 5.50001L1.41421 10.2071L0 8.79291L3.29289 5.50001Z"
                                            fill="#5974AA"
                                        />
                                    </svg>
                                </span>
                            </Link>
                        </button>
                    </div>
                </div>
                <div className="group214">
                    <div className="rectangle">
                        <img src={moni} width="150" alt="Moni Africa" />
                    </div>
                    <div className="rectangle">
                        <img src={topship} width="150" alt="Topship" />
                    </div>
                    <div className="rectangle">
                        <img src={nairametrics} width="150" alt="Naira Metrics" />
                    </div>
                    <div className="rectangle">
                        <img src={advantage} width="150" alt="Advantage Club" />
                    </div>
                </div>
            </div>
        </TestimonialStyles>
    );
}
export default Testimonial;
