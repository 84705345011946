import React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";

const CollectionsStyles = styled.div`
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 560px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 650px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* height: 650px; */
  }
  section {
    width: 100%;
    display: grid;
    height: 380px;
    grid-template-columns: 347px 803.85px;
    justify-content: space-between;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    section {
      display: flex;
      flex-direction: column;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    section {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    section {
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: 1024px) {
    .share-idea {
      margin: 0 auto;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .share-idea {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    /* .buttonwrapper{
            display: flex;
            justify-content: center;
            width: 100%;
        } */
  }
  .share-collection {
    display: grid;
    grid-template-rows: repeat(2, 165px);
    grid-row-gap: 50px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    .share-collection {
      display: flex;
      flex-direction: column;
      padding-top: 50px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .share-collection {
      /* max-width: 100%; */
      padding-top: 50px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .share-paraph {
    font-size: 36px;
    letter-spacing: -4%;
    font-weight: 600;
    font-family: "Graphik";
    text-transform: none;
    padding-left: 0;
    line-height: 50px;
    color: #03060b;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .share-paraph {
      font-size: 24px;
      line-height: 36px;
    }
  }
  .group {
    display: grid;
    grid-template-columns: repeat(3, 241.85px);
    grid-column-gap: 40px;
    /* justify-content: space-between; */
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    .group {
      text-align: justify;
      margin: 0 auto;
      padding-top: 50px;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    .group {
      display: flex;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .group {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      /* justify-content: center; */
      /* height: 580px; */
      justify-content: space-between;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
  }
  .heading1 {
    height: 30px;
    width: 77px;
    border: 2px solid #439eab;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-family: "Graphik";
    color: #439eab;
    cursor: pointer;
  }
  .heading2 {
    height: 30px;
    width: 72px;
    border: 2px solid #ef766e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-family: "Graphik";
    color: #ef766e;
  }
  .heading3 {
    height: 30px;
    width: 49px;
    border: 2px solid #707aed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-family: "Graphik";
    color: #707aed;
  }
  .heading4 {
    height: 30px;
    width: 72px;
    border: 2px solid #439eab;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-family: "Graphik";
    color: #439eab;
  }
  .heading5 {
    height: 30px;
    width: 49px;
    border: 2px solid #ef766e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-family: "Graphik";
    color: #ef766e;
  }
  .heading-text {
    margin-top: 24px;
    cursor: pointer;
  }
  h3 {
    font-size: 20px;
    color: #03060b;
    font-weight: 600;
    font-family: "Graphik";
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    font-family: "Graphik";
    text-transform: none;
    padding-left: 0;
    line-height: 24px;
    color: #445b78;
    padding-top: 12px;
    cursor: pointer;
  }
  .buttonwrapper {
    /* width: 228.12px; */
    height: 33px;
    border-radius: 100px;
    margin-top: 32px;
  }
  button,
  a.secondary {
    width: auto;
    display: flex;
    align-items: center;
    border-color: #ffffff;
    border-image: none;
    background-color: #ffffff;
    color: #5974aa;
    font-size: 18px;
    justify-content: center;
    font-family: "Graphik";
    font-weight: 600;
    border-width: 0px;
    border-style: unset;
  }
  button,
  a.secondary:hover svg {
    transition: all 0.4s ease 0s;
    transform: translateX(7px);
    position: relative;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    button {
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    a.secondary {
      font-size: 14px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .secondary {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    .group201,
    .group202,
    .group203 {
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .group201,
    .group202,
    .group203 {
      display: flex;
      /* align-items: center; */
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }
`;

function Collections({ posts }) {
  return (
    <CollectionsStyles>
      <section>
        <div className="share-idea">
          <p className="share-paraph">Our ideas help founders and teams scale their execution.</p>
          <div className="buttonwrapper">
            <button className="secondary" type="button">
              <Link to="/ideas" className="secondary">
                Learn more about our ideas
                <span>
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.29289 5.50001L0 2.20712L1.41421 0.792908L6.12132 5.50001L1.41421 10.2071L0 8.79291L3.29289 5.50001Z" fill="#5974AA" />
                  </svg>
                </span>
              </Link>
            </button>
          </div>
        </div>

        <div className="share-collection">
          <div className="group group-206">
            {posts.slice(0, 3).map((post, index) => (
              <div className="group201" key={index}>
                <div className="heading1" onClick={() => navigate(`ideas/${post?.slug.current}`)}>
                  {post.categories.title}
                </div>
                <div className="heading-text" onClick={() => navigate(`ideas/${post?.slug.current}`)}>
                  <h3>{post.title}</h3>
                  <p className="text" onClick={() => navigate(`ideas/${post?.slug.current}`)}>
                    {post.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {posts.slice(3, 6).length > 0 && (
            <div className="group group-207">
              {posts.slice(3, 6).map((post, index) => (
                <div className="group201" key={index}>
                  <div className="heading1" onClick={() => navigate(`ideas/${post?.slug.current}`)}>
                    {post.categories.title}
                  </div>
                  <div className="heading-text" onClick={() => navigate(`ideas/${post?.slug.current}`)}>
                    <h3>{post.title}</h3>
                    <p className="text" onClick={() => navigate(`ideas/${post?.slug.current}`)}>
                      {post.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </CollectionsStyles>
  );
}

export default Collections;
