import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Header from "../components/Header";
import Main from "../components/Main";
import GlobalStyles from "../styles/GlobalStyles";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

const MainStyles = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* max-width: 1440px; */
  margin: 0 auto;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
  }
`;

function HomePage({ data }) {
  const posts = data?.allSanityPost?.nodes;

  return (
    <MainStyles>
      <GlobalStyles />
      <SEO title="Home" />
      <Header />
      <Main {...{ posts }} />
      <Footer />
    </MainStyles>
  );
}

export default HomePage;

// Query data
export const query = graphql`
  query Post {
    allSanityPost(limit: 6, sort: { order: DESC, fields: publishedAt }) {
      nodes {
        title
        description
        categories {
          title
        }
        slug {
          current
        }
      }
    }
  }
`;
